<template>
	<div class="password" id="constellation-net">
		<div class="form">
			<form @submit.prevent="change_password" >
				<div class="logo">
					<div class="d-flex justify-content-center"><img src="@/apps/constelacion/assets/img/logobpbverde.png" alt="Bien para Bien"></div>
				</div>
				<div class="title text-center">
						<h1>
							Nueva contraseña
						</h1>
					</div>
				<div class="form-body">
					<div class="row form-group">
						<small style="font-weight:bold;">Por motivos de seguridad es requerído que cambies tu contraseña por una nueva antes de continuar.</small>
					</div>
					<div class="row form-group">
						<small><label for="password">Contraseña</label></small>
						<input v-model="password" type="password" name="password" id="password" class="form-control">
					</div>
					<div class="row form-group">
						<small><label for="password_confirmation">Confirmar contraseña</label></small>
						<input v-model="password_confirmation" type="password" name="password_confirmation" id="password_confirmation" class="form-control">
					</div>
					<button type="submit" class="btn principal-btn">Continuar</button>
					<br />
					<div class="text-center">
						<span class="text-center link-underline" @click="cancelar">Cancelar</span>
					</div>
				</div>
			</form>
		</div>
		<vue-particles color="#49d907"
			:particleOpacity="0.7"
			:particlesNumber="80"
			shapeType="circle"
			:particleSize="5"
			linesColor="#49d907"
			:linesWidth="2"
			:lineLinked="true"
			:lineOpacity="1"
			:linesDistance="150"
			:moveSpeed="4"
			:hoverEffect="true"
			hoverMode="grab"
			:clickEffect="true"
			clickMode="push">
		</vue-particles>

		<Modal v-if="showModal" :options="{width: '40vw'}">
			<div class="title">
				<h3>Contraseña cambiada</h3>
			</div>
			<div class="body">
				<div class="text-center">
					<p>La contraseña se cambió correctamente</p>
					<button class="btn principal-btn" @click="confirmar">Aceptar</button>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import api from '@/api/account'
	import Modal from '@/components/Modal'

	export default {
		components: {
			Modal
		}
		,data: () => ({
			password: null
			,password_confirmation: null
			,showModal: false
		})
		,methods: {
			change_password: function() {
				if (this.password.length < 5) {
					this.$notify({
						group: 'alert'
						,type: 'error'
						,title: 'Error al recuperar contraseña'
						,text: 'La contraseña tiene que tener mínimo 5 caracteres'
						,duration: 5000
					});

					return false;
				}

				if (this.password != this.password_confirmation) {
					this.$notify({
						group: 'alert'
						,type: 'error'
						,title: 'Error al recuperar contraseña'
						,text: 'La contraseña y la confirmación no son iguales'
						,duration: 5000
					});

					return false;
				}

				api.cambiar_password({
					password: this.password
					,password_confirmation: this.password_confirmation
					,force: 1
				})
				.then(res => {
					this.$log.info('res', res);
					this.showModal = true;
				})
				.catch(err => {
					this.$log.info('err', err);

					this.$helper.showAxiosError(err, 'Error');
				})
			}
			,confirmar: function() {
				this.$router.push({name: 'dashboard'});
			}
			,cancelar: function() {
				this.$store.commit('removeCredentials');
				this.$router.push({name: 'login'});	
			}
		}
	}
</script>

<style lang="scss" scoped>
@import '@/general-styles/components/forms.scss';
@import '@/general-styles/components/particles.scss';

	.password {
		background: rgba(255,255,255,.69);
    padding: 2rem;
    position: relative;
		z-index: 1;
		
		h1, h3 {
			color: #fff !important;
		}

		.logo {
			z-index: 1;
				
			img {
				width: 180px;
				height: 180px;
  			margin-bottom: 1rem;
			}	
		}
		
		.form {
			width: 400px;
			
			.principal-btn {
				margin: 1.5rem 0;
			}
		}
	}

	// @media only screen and (max-width:)
</style>